module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-52808424-5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Loveisland","short_name":"li","start_url":"/","background_color":"#48b2d2","theme_color":"#48b2d2","display":"standalone","icon":"src/images/icon-512x512.png","legacy":false,"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"33b09097fac0352922a1be05234977cd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
